import { Typography } from "@mui/material";
import ProgrammeTimeline from "../components/timeline";
import ProgrammeTimeline2 from "../components/timeline2";
import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textAlign: "center",
}));

export default function Programme() {
    return (
        <div style={{ marginTop: "3%" }} id="programme">
            <Typography
                sx={{ textAlign: "center", marginBottom: "2%" }}
                variant="h4"
            >
                Program
            </Typography>
            <Div>{"16 September 2023"}</Div>
            <ProgrammeTimeline></ProgrammeTimeline>
            <Div>{"17 September 2023"}</Div>
            <ProgrammeTimeline2></ProgrammeTimeline2>
        </div>
    );
}
