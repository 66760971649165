import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Icon from "@mui/icons-material";

export default function Info() {
    const matches = useMediaQuery("(min-width:1200px)");

    return (
        <Box
            id="startpage"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: matches ? "nowrap" : "wrap",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "40%",
                    alignItems: "start",
                }}
            >
                <Typography
                    sx={{
                        textAlign: "left",
                        margin: matches ? 0 : "1%",
                    }}
                    variant="h2"
                    gutterBottom
                    noWrap={true}
                >
                    M O A
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        textAlign: "left",
                        margin: matches ? 0 : "1%",
                        marginRight: "5px",
                    }}
                    gutterBottom
                    noWrap={true}
                >
                    & G U S T A V
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        gap: "1.5%",
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        L I L L E G Å R D
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{ margin: "0 1 1 0" }}
                        gutterBottom
                    >
                        |
                    </Typography>
                    <Typography
                        sx={{ marginRight: "2%" }}
                        variant="h6"
                        gutterBottom
                    >
                        16&nbsp; S E P T E M B E R
                    </Typography>
                </Box>
            </Box>
            <Container disableGutters sx={{ display: "flex" }}>
                <CardMedia
                    sx={{ borderRadius: matches ? "50%" : 0 }}
                    component="img"
                    image="/img4988.jpg"
                />
            </Container>
        </Box>
    );
}
