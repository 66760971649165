import "./App.css";
import Menu from "./components/menu";
import StartPage from "./pages/startPage";
import Programme from "./pages/programme";
import Info from "./pages/info";
import OsaForm from "./pages/osa";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const theme = createTheme({
    typography: {
        h2: {
            color: "#1C468D",
        },
        h3: {
            color: "#1C468D",
        },
        h4: {
            color: "#1C468D",
            opacity: "50%",
            fontFamily: "georgia",
        },
        h6: {
            color: "#1C468D",
        },
        fontFamily: ["Times New Roman"].join(","),
        fontStyle: "italic",
    },
});

function App() {
    const matches = useMediaQuery("(min-width:1200px)");
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <header className="App-header">
                    <Menu></Menu>
                </header>
                <CssBaseline />
                <Container disableGutters>
                    <StartPage></StartPage>
                </Container>
                {matches && <Divider sx={{ mt: "3%" }} />}
                <Container maxWidth="md" sx={{ mt: 2 }}>
                    <OsaForm></OsaForm>
                </Container>
                <Divider
                    sx={{
                        mt: 2,
                    }}
                />
                <Container maxWidth="md">
                    <Info></Info>
                </Container>
                <Divider sx={{ mt: "3%" }} />
                <Container maxWidth="md" sx={{ mt: "2%" }}>
                    <Programme></Programme>
                </Container>
            </ThemeProvider>
        </div>
    );
}

export default App;
