import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Info() {
    const matches = useMediaQuery("(min-width:1200px)");
    return (
        <Box style={{ marginTop: "3%" }} id="info">
            <Typography variant="h4" gutterBottom sx={{}}>
                ADRESS & TRANSPORT
            </Typography>
            <Typography variant="body1" sx={{}}>
                Lillgård
            </Typography>
            <Typography variant="body1" sx={{}}>
                Fredriksrovägen 85
            </Typography>
            <Typography variant="body1" sx={{}}>
                Kävlinge
            </Typography>
            <Typography variant="body1" sx={{}}>
                Det smidigaste transportmedlet är taxi från Lund. Vill ni köra
                bil finns det möjlighet till parkering, men ni får gärna samåka
                då parkeringplatserna är något begränsade.
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                BOENDE
            </Typography>
            <Typography variant="body1" sx={{}}>
                Lillegård ligger inte långt från Lund och i Lund finns det både
                hotell och vandrarhem. Google är din vän här!
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
                Vi har också genom Lillegård fått tag i en kick-back fri
                rabattkod på Hotel Lundia (15%).
            </Typography>
            <Typography>Hotel Lundia: GLLG</Typography>
            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                KLÄDSEL
            </Typography>
            <Box>
                <Typography variant="body1" sx={{}}>
                    Klädkod: Kavaj
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                    Enligt våra bästa googlesökningar innebär detta:
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                    "Kavaj innebär för herrar att man ska bära kostym.
                    Valfriheten är dock stor vad gäller färg, både på själva
                    kostymen och skjortan. Kostymen kan vara både tvådelad,
                    eller tredelad (med väst)."
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                    "Kavaj innebär för damer klänning, kjol, byxdress eller
                    dräkt. Klänning eller kjol ska gå minst till knäna."
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                    Men det absolut viktigaste är såklart att ni känner er fina
                    under dagen!
                </Typography>
            </Box>
            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                GÅVOR
            </Typography>
            <Typography variant="body1" sx={{}}>
                Det vi önskar oss mest av allt är att ni delar dagen med oss!
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
                Vill ni ändå ge något så önskar vi oss ett bidrag till vår
                bröllopsresa.
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                BARN
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
                Vi har valt att ha ett barnfritt bröllop. Småkusiner och
                syskonbarn har lovats kompensation vid ett senare tillfälle!
                Ammande spädbarn får självklart vara med.
            </Typography>
            <Box sx={{ display: "flex" }}>
                <Box>
                    <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                        TOASTMASTERS
                    </Typography>
                    <Typography variant="body1" sx={{}}>
                        Om man önskar hålla tal, uppträda med något spex eller
                        sjunga en vacker sång så finns här kontaktuppgifter till
                        våra fantastiska Toastmasters Johan & Victoria.
                    </Typography>
                    {!matches && (
                        <Container disableGutters sx={{ display: "flex" }}>
                            <CardMedia
                                sx={{ borderRadius: "50%" }}
                                component="img"
                                image="/jv2.png"
                            />
                        </Container>
                    )}
                    <Box
                        sx={{
                            display: matches ? "block" : "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            Telefon: 0707945753 | 0768343866
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            E-mail: johanvix.tm@gmail.com
                        </Typography>
                    </Box>
                </Box>
                {matches && (
                    <Container disableGutters sx={{ display: "flex" }}>
                        <CardMedia
                            sx={{ borderRadius: "50%" }}
                            component="img"
                            image="/jv2.png"
                        />
                    </Container>
                )}
            </Box>
        </Box>
    );
}
