import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";

const encode = (formName, data) => {
    data = {
        data: data,
        "form-name": formName,
    };
    return Object.keys(data)
        .map((key) => {
            var encodedComponent = encodeURIComponent(key) + "=";
            if (typeof data[key] === "string") {
                encodedComponent += encodeURIComponent(data[key]);
            } else {
                encodedComponent += encodeURIComponent(
                    JSON.stringify(data[key])
                );
            }
            return encodedComponent;
        })
        .join("&");
};

export default function OsaForm() {
    const [formSuccess, setFormSuccess] = React.useState(false);
    const [inputFields, setInputFields] = React.useState([
        {
            present: "",
            name: "",
            email: "",
            favoriteSong: "",
            allergiesExtras: "",
        },
    ]);
    const handleChangeInput = (index, event) => {
        const values = [...inputFields];
        values[index][event.target.name] = event.target.value;
        setInputFields(values);
    };

    const handleAddFields = () => {
        setInputFields([
            ...inputFields,
            {
                present: "",
                name: "",
                email: "",
                favoriteSong: "",
                allergiesExtras: "",
            },
        ]);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode("osa", inputFields),
        })
            .then((res) => {
                if (res.ok) {
                    setFormSuccess(true);
                } else {
                    alert(
                        `Hoppsan, något gick fel. Ring support (Gustav). ${res.status}`
                    );
                }
            })
            .catch((error) =>
                alert(
                    `Hoppsan, något gick fel. Ring support (Gustav). ${error}`
                )
            );
    };

    return (
        <div style={{ marginTop: "3%" }} id="OSA">
            <Typography
                variant="h3"
                gutterBottom
                style={{ textAlign: "center" }}
            >
                OSA
            </Typography>
            <Typography
                variant="body1"
                gutterBottom
                style={{ textAlign: "center", color: "#1C468D" }}
            >
                SENAST SISTA JUNI
            </Typography>

            <Box
                component="form"
                sx={{
                    "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                name="osa"
            >
                {inputFields.map((inputField, index) => (
                    <Box
                        key={index}
                        sx={{
                            "& > :not(style)": { m: 1 },
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "top",
                                justifyContent: "space-between",
                            }}
                        >
                            <FormControl>
                                <div>Närvarar</div>
                                <RadioGroup
                                    aria-labelledby="radio-buttons-group"
                                    name="present"
                                    value={inputField.present}
                                    onChange={(event) =>
                                        handleChangeInput(index, event)
                                    }
                                >
                                    <FormControlLabel
                                        value="Ja"
                                        control={<Radio />}
                                        label="Ja"
                                    />
                                    <FormControlLabel
                                        value="Nej"
                                        control={<Radio />}
                                        label="Nej"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl>
                                <IconButton
                                    onClick={() => handleRemoveFields(index)}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                "& > :not(style)": { mr: 1, mb: 1 },
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            <FormControl>
                                <TextField
                                    value={inputField.name}
                                    onChange={(event) =>
                                        handleChangeInput(index, event)
                                    }
                                    name="name"
                                    id="outlined"
                                    label="Namn"
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    value={inputField.email}
                                    onChange={(event) =>
                                        handleChangeInput(index, event)
                                    }
                                    name="email"
                                    id="outlined"
                                    label="E-post"
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    value={inputField.favoriteSong}
                                    onChange={(event) =>
                                        handleChangeInput(index, event)
                                    }
                                    name="favoriteSong"
                                    id="outlined"
                                    label="Favoritlåt"
                                />
                            </FormControl>
                        </Box>
                        <FormControl>
                            <TextField
                                value={inputField.allergies}
                                onChange={(event) =>
                                    handleChangeInput(index, event)
                                }
                                id="outlined"
                                name="allergiesExtras"
                                label="Matpreferenser, Allergier & övrig info"
                            />
                        </FormControl>
                    </Box>
                ))}
                <Box
                    sx={{
                        "& > :not(style)": { mr: 1 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                    }}
                >
                    <Button variant="text" onClick={() => handleAddFields()}>
                        Lägg till person
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        color={formSuccess ? "success" : "primary"}
                    >
                        {formSuccess ? "Tack!" : "Skicka"}
                    </Button>
                </Box>
            </Box>
        </div>
    );
}
