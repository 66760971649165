import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

export default function Menu() {
    return (
        <Box sx={{ marginBottom: "3%", flexGrow: 1 }}>
            <div>
                <Toolbar sx={{ flex: true, justifyContent: "center" }}>
                    <Button
                        disableRipple
                        variant="text"
                        color="inherit"
                        sx={{
                            margin: "1%",
                            fontSize: "1rem",
                            "&:hover": {
                                color: "#1C468D",
                                backgroundColor: "white",
                            },
                        }}
                        href="#startpage"
                    >
                        Startsida
                    </Button>
                    <Button
                        disableRipple
                        variant="text"
                        color="inherit"
                        sx={{
                            margin: "1%",
                            fontSize: "1rem",
                            "&:hover": {
                                color: "#1C468D",
                                backgroundColor: "white",
                            },
                        }}
                        href="#OSA"
                    >
                        OSA
                    </Button>
                    <Button
                        disableRipple
                        variant="text"
                        color="inherit"
                        sx={{
                            margin: "1%",
                            fontSize: "1rem",
                            "&:hover": {
                                color: "#1C468D",
                                backgroundColor: "white",
                            },
                        }}
                        href="#info"
                    >
                        Info
                    </Button>
                    <Button
                        disableRipple
                        variant="text"
                        color="inherit"
                        sx={{
                            margin: "1%",
                            fontSize: "1rem",
                            "&:hover": {
                                color: "#1C468D",
                                backgroundColor: "white",
                            },
                        }}
                        href="#programme"
                    >
                        Program
                    </Button>
                </Toolbar>
            </div>
        </Box>
    );
}
